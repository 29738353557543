// All

@import './bootstrap.css';


:root {
    --primary: #0FD42C;
    --secondary: #5CF1FF;
}

*
{
    margin: 0;
    padding: 0;
    visibility: inherit;
}

html,
body
{
    font-family: 'Raleway', sans-serif;
    color: white;
    letter-spacing: 1px;
    scroll-behavior: smooth;
}


.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}


.segeroDot {
	font-family: 'Times New Roman', Times, serif;
	color: #0FD42C;
}

a:hover {
    cursor: pointer;
}

ul {
    padding-left: 30px;
    list-style-position: outside;
    list-style-type: circle;
}

// Misc

textarea:hover, 
input:hover, 
textarea:active, 
input:active, 
textarea:focus, 
input:focus,
a:focus,
a:active,
a:hover,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active {
    outline:0px !important;
    box-shadow: none !important;
}

a, a:hover {
    color: inherit;
    text-decoration: none;
}
p {
    margin: 0;
}

// Navbar

.navbarLogo {
    font-size: 1.5em  !important;
}


nav li a {
    font-size: 1.2em;
    padding: 20px;
    
}
  
nav a:hover {
    color: var(--primary);
    text-decoration: none;
}

#navbar {
    font-weight: 100;
    color:white;
    transition: top 0.3s, background-color 0.5s;
    position: fixed;
    z-index: 3;
    top: 0;
    width: 100%;
    display: block;
    animation: fadeInDown 2s;
}

#navbar a {
    float: center;
    display: block;
    text-align: center;
    padding: 15px;
    text-decoration: none;
    font-size: 17px;
    transition: 0.5s;

    @media screen and (min-width: 768px) {
        float: left;
    }
}


#navbar.dark {
    color: black;
}

.navbar-toggler:hover {
    cursor: pointer;
}

.navbar-toggler span {
    display: block;
    background-color: white;
    height: 3px;
    width: 25px;
    margin-top: 5px;
    margin-bottom: 5px;
    position: relative;
    left: 0;
    opacity: 1;
    transition: all 0.35s ease-out;
    transform-origin: center left;
    display: block;

    @media screen and (min-width: 768px) {
        display: none;
    }
}


.navbar-toggler span:nth-child(1) {
    margin-top: 0.3em;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
    transform: translate(15%, -33%) rotate(45deg);
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
    opacity: 0;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
    transform: translate(15%, 33%) rotate(-45deg) ;
}

.navbar-toggler span:nth-child(1) {
    transform: translate(0%, 0%) rotate(0deg) ;
}

.navbar-toggler span:nth-child(2) {
    opacity: 1;
}

.navbar-toggler span:nth-child(3) {
    transform: translate(0%, 0%) rotate(0deg) ;
}




// Main 

#mainContent {
    overflow-y: hidden;
    transform-origin: center center;
    animation: fadeIn 1s;
    margin: 0 0px;
    
    

    // @media (min-width: 576px) {
    //     margin: 0 100px ;
    // }

    // @media (min-width: 768px) {
    //     margin: 0 00px ;
    // }

    @media (min-width: 992px) {
        margin: 0 100px ;
    }

    @media (min-width: 1200px) {
        margin: 0 200px ;
    }
    
}

#mainContent.fadeOut {
    transform-origin: center center;
    animation: fadeOutUp 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
}

// Home 

#home {
    height: 105vh;

    @media (min-width: 768px) {
        margin: 0px 100px;
    }


    .row {

        h1 {
            padding-top: 15%;
            font-weight: 100;
            font-size: 4em;
            height: 40%;

            @media (min-width: 576px) {
                font-size: 5em;
                height: 45%;
            }

            @media (min-width: 992px) {
                font-size: 6em;
            }
            
        }

        .slogan {
            height: 60%;

            @media (min-width: 576px) {
                height: 55%;
            }

            margin:0 auto;
            width:100%;

        }

        h2 {
            font-weight: 100; 
            font-size: 1.8em;
            text-align: center;
            
            @media (min-width: 576px) {
                font-size: 2.5em;
            }

            @media (min-width: 992px) {
                font-size: 3em;
            }
        }

        h2:nth-child(1) {
            margin-right: 50px;

            @media (min-width: 576px) {
                margin-right: 150px;
            }
        }

        h2:nth-child(2) {
            margin-left: 50px;

            @media (min-width: 576px) {
                margin-left: 150px;
            }
        }
    }
}



// About 

#about {

    .openingStatement {
        margin-bottom: 200px;

        h3 {
            font-weight: 100;
            font-size: 1.7em;

            @media (min-width: 576px) {
                font-size: 3em;
            }
        }
    }

    .aboutContainer {
        div {
            padding: 100px 0px;

            img {
                height: 250px;

                @media (min-width: 576px) {
                    height: 200px;
                }

                @media (min-width: 768px) {
                    height: 250px;
                }

                @media (min-width: 992px) {
                    height: 300px;
                }
            }
        }
        .imgContainer {

            @media (max-width: 576px) {
                padding: 0;
                text-align: center !important;
            }
        }
    }
}




// Services / Four Pillars 

#services {

    .container {
        height: 100vh;

        .row {
            margin-top: 60px;

            @media (min-width: 576px) {
                margin-top: 120px;
            }

            .title {
                font-size: 2em;
                font-weight: 100;
                margin-bottom: 10px;

                @media (min-width: 768px) {
                    font-size: 3em;
                }
            }
        

            .pillar {
                margin: auto;
                padding: 0;
                height: 80px;
                margin: 20px 0;
                animation: floating 3s ease-in-out infinite alternate;


                @media (min-width: 576px) {
                    height: 90px;
                }

                @media (min-width: 768px) {
                    height: 100px;
                }

            
                div {
                    margin: auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: inherit;
                    width: 120px;


                    @media (min-width: 576px) {
                        width: 160px;
                    }

                    @media (min-width: 768px) {
                        width: 200px;
                    }

                    // border-radius: 10px;
                    // box-shadow: rgba(92, 241, 255, 0.4) -5px 5px, rgba(92, 241, 255, 0.3) -10px 10px, rgba(92, 241, 255, 0.2) -15px 15px, rgba(92, 241, 255, 0.1) -20px 20px, rgba(92, 241, 255, 0.05) -25px 25px;
            
                    transition: ease-in-out 0.3s;
            
                    h2 {
                        font-size: 1.2em;
                        font-weight: 100;
                        text-align: center;

                        @media (min-width: 768px) {
                            font-size: 1.5em;
                        }

                        @media (min-width: 768px) {
                            font-size: 1.8em;
                        }
                        
                    }
                }
            
                div:hover {
                    // color: var(--secondary);
                    transform: translateY(-10%);
                }
            
                a {
                    height: inherit;
                }
            
            }
            
            .pillar:nth-child(2) {
                animation-delay: 0.3s;
            }
            .pillar:nth-child(3) {
                animation-delay: 0.6s;
            }
            .pillar:nth-child(4) {
                animation-delay: 0.9s;
            }
        }
    }

}

.glow {
    width: 200px;
    height: 100px;
    border: none;
    outline: none;
    color: #fff;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    
    
}

.glow:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 1;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
}

.glow:active {
    color: #000
}

.glow:after {
    transition: opacity 0.5s ease-in-out;
}

.glow:hover:after {
    opacity: 0.5;
}

.glow:hover:before {
    opacity: 1;
}

.glow:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #111;
    left: 0;
    top: 0;
    border-radius: 10px;
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}

@keyframes floating {
    0% { transform: translateY(0%);}
    50% { transform: translateY(15%);}
    100% { transform: translateY(0%);; }
}



// Overlays 

.overlaySection {
    overflow-y: auto;
    display: none;
    color: white;
    position: fixed; 
    width: 100%;
    height: 100%; 
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2; 
    transform-origin: 50% 0%;
    animation: fadeOutLeftOverlay 2.2s;
    animation-fill-mode: forwards;
    // animation-timing-function: ease-out;
    // animation-timing-function: cubic-bezier(.01, 1,.67, .83);
    animation-timing-function: cubic-bezier(.01,1.01,.23,1);

    padding: 0;

    .returnBtn {
        color:white;
        background-color: transparent;
    }

    .content {
        font-weight: 300;
        font-size: 0.7em;
        margin: 50px 0px;



        // @media (min-width: 576px) {
        //     margin: 0 100px ;
        // }
    
        @media (min-width: 768px) {
            font-size: 1em;
            margin: 100px 0px;
        }
    
        @media (min-width: 992px) {
            margin: 100px 100px;
        }
    
        @media (min-width: 1200px) {
            margin: 100px 200px;
        }

        p, ul {
            margin: 20px 0;
        }

        .title {
            display: flex;
            margin: 20px 0;
            flex-direction: column;

            @media (min-width: 576px) {
                flex-direction: row;
            }

            h2 {
                font-size: 4em;
                font-weight: 100;
                padding-right: 20px;

                    
                @media (min-width: 576px) {
                    font-size: 4.5em;
                }
            }

            p {
                font-size: 1.5em; 
                font-weight: 200;
            }
        }

        h3 {
            font-weight: 100;
        }

        .consultingOption {
            color: white;
            background-color: rgba(0,0,0, 0.5);
            border-top: 1px solid white;
            border-bottom: 1px solid white;
            border-radius: 0px;
            width: 100%;
            height: auto;
            white-space: normal;

        }

        .consultingOption.selected {
            background-color: white;
            color: black;
        }
    }

}

#consultingBlock {
    animation-duration: 0.8s !important;
}


.pageChangeBtn {
    text-align: center;
    margin: 20px auto;
    position: relative; 
    button {
        font-size: 1.2em;
        font-weight: 200;
        background-color: transparent;
        color: white;

        @media (min-width: 576px) {
            font-size: 1.5em;
        }
    }
}


// Meet the Team

#meetTheTeam {
    color: black;
    animation: fadeOutUpOverlay 2.2s;

    button {
        color: black;
    }

    .directorBios {
        margin-top: 50px;
        background-color: rgba(255, 255, 255, 0.2);
        // background-color: rgba(150, 150, 150, 0.1);
        border-radius: 10px;

        .bio {
            padding: 50px 20px;

            @media (min-width: 576px) {
                padding: 50px;
            }

            h3 {
                font-weight: 200;
            }

            h4 {
                font-weight: 100;
            }

            img {
                margin: auto;
                border-radius: 100%;
            }
        }

        .bio:nth-child(2):before {
            content: "";
            position: absolute;

            @media (max-width: 768px) {
                left: 15%;
                top: 0;
                width: 70%;
                border-top: 2px solid #464646;
            }

            @media (min-width: 768px) {
                bottom: 15%;
                left:0;
                height: 70%;
                border-left: 2px solid #464646;
            }
        }

    }
}


// Contact 

#contact {
    animation: fadeOutUpOverlay 2.2s;

    .content {
        margin: 20px 0;

        .col-12 {
            margin: 30px 0;
        }

        .title {
            display: block;
        }
        p {
            font-size: 1.5em;
            font-weight: 100;
        }

        #callBtn {
            font-size: 1.5em;
            font-weight: 200;
            color: white;
            background-color: transparent;
            border: 1px solid white;
            padding: 10px 35px;

        }

        form {
            width: 250px;

            @media (min-width: 768px) {
                width: 500px;
            }

            #submitBtn {
                color: white;
                background-color: transparent;
                border: 1px solid white;
                padding: 10px 35px;
                margin-top: 20px;
            }
        }



    }

}


// Animations 

// Button animation
.slide {
    transition: 0.5s;
}
.slide:hover,
.slide:focus {
    box-shadow: inset 200px 0 0 0 white !important;
    color: black !important;
    background-color: white !important;
}

.slide:hover#submitBtn,
.slide:focus#submitBtn{
    box-shadow: inset 50px 0 0 0 white !important;
}

.animated {
    animation-duration: 1s;
    animation-fill-mode: both;
}

.fadeInLeftOverlay {
    animation: fadeInLeftOverlay 2.2s;
    animation-timing-function: cubic-bezier(1,.01,.83,.67);
}



.fadeInUpOverlay {
    animation: fadeInUpOverlay 2.2s !important;
}

.fadeIn {
    animation-name: fadeIn;
}

@keyframes fadeIn
{
	0% { 
        opacity: 0; 
    }
    100% { 
        opacity: 1;
    }
}


@keyframes fadeOut
{
	0% { 
        transform:  translateY(-20%); 
        opacity: 0;
        visibility: hidden;
    }
	100% { 
        transform:  translateY(0); 
        opacity: 1;
        visibility: visible;
    }
}


@keyframes fadeInLeftOverlay
{
	0% { 
        opacity: 0;
    }
	100% { 
        opacity: 1;
    }
}

@keyframes fadeOutLeftOverlay
{
	0% { 
        opacity: 1;
    }
	100% { 
        opacity: 0;
    }
}


@keyframes fadeInUpOverlay
{
	0% { 
        transform:  translateY(100%); 
        opacity: 0;

    }
	100% { 
        transform:  translateY(0); 
        opacity: 1;
    }
}

@keyframes fadeOutUpOverlay
{
	0% { 
        transform:  translateY(0); 
        opacity: 1;

    }
	100% { 
        transform:  translateY(100%); 
        opacity: 0;
    }
}


@keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translate3d(0, 100%, 0);
    }
  
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
}
  
.fadeInUp {
    animation-name: fadeInUp;
}


@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translate3d(0, -100%, 0);
    }
  
    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}
  

@keyframes fadeOutUp {
    from {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }

    to {
        opacity: 0;
        transform: translate3d(0, -100, 0);
    }
}
  